module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yarn play with Shirley","short_name":"YarnPlay","start_url":"/","background_color":"#fff","theme_color":"#dd6b20","display":"standalone","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"45ddfc69e944eb775b92c436ac855a14"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
